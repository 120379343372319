.header {
  .header();
  padding: 25px 0;
  border-bottom: 3px solid #ff1908;
  z-index: 100;
}

section#content {
  z-index: 90;
  padding: 60px 0;
  position: relative;
  top: -110px;
  margin-bottom: -110px;
  padding-top: 180px;
}

.footer {
  background: @dark;
  padding: 40px 0;
  max-height: 100%;
  line-height: 24px;
  text-align: center;
  .wrapper {
    .clearfix();
  }
  .disclaimer {
    color: #8c8c8c;
    font-size: 0.75em;
    margin: 10px auto 4px;
    max-width: 650px;
  }
  .copyright {
    font-size: 0.875em;
    margin-top: 0px;
    font-weight: 700;
    color: @white;
    a {
      color: @white;
    }
  }
}